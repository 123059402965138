var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission:
          _vm.type == "PRODUCT"
            ? _vm.domain == "PRODUCT_DETAIL"
              ? !_vm.isTongChou
                ? "isTongchou"
                : "info:button:edit"
              : !_vm.isTongChou
              ? "isTongchou"
              : "coefficientPar:button:edit"
            : _vm.type == "PLATFORM"
            ? _vm.domain == "PRODUCT_DETAIL"
              ? !_vm.isTongChou
                ? "isTongchou"
                : "palatform:button:edit"
              : !_vm.isTongChou
              ? "isTongchou"
              : "palatform:coefficientPar:button:edit"
            : _vm.type == "GLOBAL"
            ? _vm.domain == "PRODUCT_DETAIL"
              ? "system:productDetail:edit"
              : "system:coefficientPar:edit"
            : "",
        "destroy-on-close": "",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            {
              staticStyle: { "font-weight": "400" },
              attrs: { "label-width": _vm.labelWidth, label: "名称" },
            },
            [_vm._v(_vm._s(_vm.curRow.title))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sort))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用部门" } },
            _vm._l(_vm.curRow.departments, function (one) {
              return _c("a-tag", { key: one.departId }, [
                _vm._v(_vm._s(one.departName)),
              ])
            }),
            1
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用职位" } },
            _vm._l(_vm.curRow.positions, function (one) {
              return _c("a-tag", { key: one.positionId }, [
                _vm._v(
                  _vm._s(one.positionName + "-") +
                    _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                ),
              ])
            }),
            1
          ),
          _vm.curRow.canChapter || _vm.domain != "PRODUCT_DETAIL"
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "适用阶段" } },
                _vm._l(_vm.curRow.stages, function (one) {
                  return _c("a-tag", { key: one.stageId }, [
                    _vm._v(
                      _vm._s(one.stageName + "-") +
                        _vm._s(one.type == "VIEW" ? "查看" : "编辑")
                    ),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm.domain == "PRODUCT_DETAIL"
            ? _c("info-item", { attrs: { label: "适用到章节" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.curRow.canChapter ? "是" : "否") +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm.domain == "TASK_DETAIL"
            ? _c("info-item", { attrs: { label: "章节范围" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.curRow.chapterRange
                        ? _vm.getCalcRange(_vm.curRow.chapterRange)
                        : ""
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _c("info-item", { attrs: { label: "描述" } }, [
            _vm._v("\n      " + _vm._s(_vm.curRow.description) + "\n    "),
          ]),
          _vm.isSync
            ? _c("info-item", { attrs: { label: "允许同步" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.curRow.canSync || _vm.curRow.canSync === null
                        ? "是"
                        : "否"
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _c("g-title", [_vm._v("正文")]),
          _vm.curRow.cardList
            ? _vm._l(_vm.curRow.cardList, function (one) {
                return _c(
                  "a-form",
                  { key: one.id },
                  [
                    _c(
                      "a-form-item",
                      { attrs: { label: _vm._f("typeFilter")(one.type) } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("canAddFilter")(one.canAdd)) +
                            "\n        "
                        ),
                      ]
                    ),
                    _c("a-form-item", { attrs: { label: "是否必填" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            one.dataList
                              ? one.dataList[0].valueRequired == "NO"
                                ? "否"
                                : "是"
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    _c("a-form-item", { attrs: { label: "输入框说明" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            one.dataList ? one.dataList[0].description : ""
                          ) +
                          "\n        "
                      ),
                    ]),
                    one.type == "DYNAMIC_FORM" && one.dataList
                      ? _vm._l(one.dataList, function (item, itemIndex) {
                          return _c(
                            "div",
                            {
                              key: itemIndex,
                              staticStyle: { "margin-bottom": "-12px" },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "子选项" } },
                                [
                                  _c("span", [_vm._v(_vm._s(item.value))]),
                                  _c(
                                    "span",
                                    {
                                      style: item.value
                                        ? "margin-left:20px;"
                                        : "",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.type == "INPUT"
                                            ? "文本录入"
                                            : item.type == "SELECT"
                                            ? "下拉选项"
                                            : "标签"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  style:
                                    item.type != "INPUT"
                                      ? "margin-bottom:36px;margin-top:24px;"
                                      : "height: 11px;",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-bottom": "0px" } },
                                    [
                                      _c(
                                        "a-row",
                                        [
                                          item.type == "TAG"
                                            ? _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "134px",
                                                    "padding-right": "66px",
                                                  },
                                                  attrs: { span: 16 },
                                                },
                                                [
                                                  _vm._l(
                                                    item.options,
                                                    function (i, index) {
                                                      return [
                                                        _c(
                                                          "a-tag",
                                                          {
                                                            key: index,
                                                            staticStyle: {
                                                              "margin-bottom":
                                                                "8px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(i.label)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "134px",
                                                    "padding-right": "66px",
                                                    "margin-top": "-8px",
                                                  },
                                                  attrs: { span: 16 },
                                                },
                                                _vm._l(
                                                  item.options,
                                                  function (i, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "ant-badge-status-dot ant-badge-status-processing",
                                                        }),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(i.label)
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    one.type != "INPUT_NUMBER"
                      ? [
                          one.type == "SELECT" || one.type == "TAG"
                            ? _c(
                                "a-form-item",
                                { attrs: { label: "选项个数" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        one.dataList &&
                                          one.dataList[0].selectCount
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                "a-form-item",
                                { attrs: { label: "输入框字数" } },
                                [
                                  one.dataList[0].limitRange
                                    ? _c("div", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getLimitRange(
                                                one.dataList[0].limitRange
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                        ]
                      : _vm._e(),
                    one.type == "SELECT" || one.type == "TAG"
                      ? _c(
                          "a-form-item",
                          {
                            class:
                              one.type == "SELECT"
                                ? "option-item"
                                : "option-item option-tag-item",
                            staticStyle: { "padding-right": "66px" },
                            attrs: {
                              label:
                                one.type == "SELECT" ? "选项内容" : "标签内容",
                            },
                          },
                          [
                            one.dataList
                              ? [
                                  _vm._l(
                                    one.dataList[0].options,
                                    function (i, idx) {
                                      return [
                                        one.type == "TAG"
                                          ? _c(
                                              "a-tag",
                                              {
                                                key: idx,
                                                staticStyle: {
                                                  "margin-bottom": "8px",
                                                },
                                              },
                                              [_vm._v(_vm._s(i.label))]
                                            )
                                          : _c(
                                              "div",
                                              {
                                                key: idx,
                                                style:
                                                  idx == 0
                                                    ? ""
                                                    : "margin-top:-15px;",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "ant-badge-status-dot ant-badge-status-processing",
                                                }),
                                                _c("span", [
                                                  _vm._v(_vm._s(i.label)),
                                                ]),
                                              ]
                                            ),
                                      ]
                                    }
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  2
                )
              })
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                staticStyle: { "padding-bottom": "65px" },
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                {
                  staticClass: "first-form-item",
                  attrs: { label: "名称", prop: "sequenceNo" },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sort", $$v)
                      },
                      expression: "formData.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "适用部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      treeDefaultExpandAll: "",
                      "tree-checkable": "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                      placeholder: "请选择适用部门",
                    },
                    on: { change: _vm.changeDepartList },
                    model: {
                      value: _vm.selectedDeparts,
                      callback: function ($$v) {
                        _vm.selectedDeparts = $$v
                      },
                      expression: "selectedDeparts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "适用职位", prop: "selectedPositions" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.getTreePositionList,
                      "search-placeholder": "选择职位",
                      placeholder: "请选择适用职位",
                    },
                    on: { change: _vm.changePositionList },
                    model: {
                      value: _vm.selectedPositions,
                      callback: function ($$v) {
                        _vm.selectedPositions = $$v
                      },
                      expression: "selectedPositions",
                    },
                  }),
                ],
                1
              ),
              _vm.formData.canChapter || _vm.domain == "TASK_DETAIL"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "适用阶段", prop: "selectedStages" } },
                    [
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tree-checkable": "",
                          "tree-data": _vm.getTreeStageList,
                          "search-placeholder": "选择阶段",
                          placeholder: "请选择适用阶段",
                        },
                        on: { change: _vm.changeStageList },
                        model: {
                          value: _vm.selectedStages,
                          callback: function ($$v) {
                            _vm.selectedStages = $$v
                          },
                          expression: "selectedStages",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.domain == "PRODUCT_DETAIL"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "适用到章节" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.formData.canChapter,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "canChapter", $$v)
                            },
                            expression: "formData.canChapter",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: true } }, [
                            _vm._v("是"),
                          ]),
                          _c("a-radio", { attrs: { value: false } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.domain == "TASK_DETAIL"
                ? _c(
                    "a-form-item",
                    { attrs: { label: "章节范围" } },
                    [
                      _vm.formData.chapterRange
                        ? _c("edit-range-select", {
                            attrs: {
                              formData: _vm.formData.chapterRange,
                              rangeKey: "type",
                              valueKey: "value",
                              needUnit: false,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { label: "描述" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description",
                    },
                  }),
                ],
                1
              ),
              _vm.isSync
                ? _c(
                    "a-form-item",
                    { attrs: { label: "允许同步" } },
                    [
                      _c("a-switch", {
                        model: {
                          value: _vm.canSync,
                          callback: function ($$v) {
                            _vm.canSync = $$v
                          },
                          expression: "canSync",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("g-title", [_vm._v("正文")]),
              _vm._l(_vm.formData.formItems, function (one, index) {
                return _c("a-form-model-item", {
                  key: index,
                  attrs: { label: one.label, prop: one.prop },
                })
              }),
              _vm.formData.cardList
                ? [
                    _vm._l(_vm.formData.cardList, function (one, index) {
                      return [
                        _c(
                          "div",
                          { key: one.id, staticClass: "edit-form" },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                staticClass: "edit-form-remove",
                                attrs: {
                                  label: _vm._f("typeFilter")(one.type),
                                },
                              },
                              [
                                one.type == "DYNAMIC_FORM"
                                  ? _c(
                                      "a-checkbox",
                                      {
                                        model: {
                                          value: one.canAdd,
                                          callback: function ($$v) {
                                            _vm.$set(one, "canAdd", $$v)
                                          },
                                          expression: "one.canAdd",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                填写时是否允许加行\n              "
                                        ),
                                      ]
                                    )
                                  : _c("div"),
                                _c(
                                  "div",
                                  { staticClass: "card-remove" },
                                  [
                                    _c("a-button", {
                                      staticStyle: { position: "relative" },
                                      attrs: {
                                        shape: "circle",
                                        size: "small",
                                        icon: "close",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteCard(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              { attrs: { label: "是否必填" } },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    model: {
                                      value: one.dataList[0].valueRequired,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          one.dataList[0],
                                          "valueRequired",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "one.dataList[0].valueRequired",
                                    },
                                  },
                                  [
                                    _c("a-radio", { attrs: { value: "YES" } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c("a-radio", { attrs: { value: "NO" } }, [
                                      _vm._v("否"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-form-item",
                              {
                                staticClass: "description",
                                attrs: { label: "输入框说明" },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: one.dataList[0].description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        one.dataList[0],
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "one.dataList[0].description",
                                  },
                                }),
                              ],
                              1
                            ),
                            one.type == "DYNAMIC_FORM"
                              ? _vm._l(one.dataList, function (item, i) {
                                  return _c(
                                    "div",
                                    { key: i },
                                    [
                                      _c(
                                        "a-form-model-item",
                                        {
                                          staticClass: "dt-form",
                                          attrs: { label: "子选项" },
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            [
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                    display: "flex",
                                                    flex: "1",
                                                  },
                                                },
                                                [
                                                  _c("a-input", {
                                                    attrs: {
                                                      placeholder: "请输入",
                                                    },
                                                    model: {
                                                      value: item.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.value",
                                                    },
                                                  }),
                                                  _c(
                                                    "a-select",
                                                    {
                                                      ref: "select",
                                                      refInFor: true,
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                      },
                                                      attrs: { allowClear: "" },
                                                      on: {
                                                        change: (value) =>
                                                          _vm.changeSelect(
                                                            value,
                                                            item
                                                          ),
                                                      },
                                                      model: {
                                                        value: item.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.type",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "INPUT",
                                                          },
                                                        },
                                                        [_vm._v("文本录入")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "SELECT",
                                                          },
                                                        },
                                                        [_vm._v("下拉选项")]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "TAG",
                                                          },
                                                        },
                                                        [_vm._v("标签")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-col",
                                                {
                                                  staticStyle: {
                                                    "min-width": "45px",
                                                    "margin-left": "10px",
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                    "align-items": "center",
                                                  },
                                                },
                                                [
                                                  _c("a-button", {
                                                    staticClass: "item-btn",
                                                    attrs: {
                                                      shape: "circle",
                                                      size: "small",
                                                      icon: "minus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delItem(
                                                          one.dataList,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("a-button", {
                                                    staticClass: "item-btn",
                                                    staticStyle: {
                                                      "margin-left": "8px",
                                                    },
                                                    attrs: {
                                                      shape: "circle",
                                                      size: "small",
                                                      icon: "plus",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addItem(
                                                          one.dataList,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      item.type == "SELECT"
                                        ? [
                                            _c(
                                              "a-form-item",
                                              { attrs: { label: "下拉选项" } },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          flex: "1",
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          on: {
                                                            change: (e) => {
                                                              _vm.inputSearchChange(
                                                                e,
                                                                i,
                                                                one.dataList
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.optionsPushValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "optionsPushValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.optionsPushValue",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                          "min-width": "45px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            staticStyle: {
                                                              "max-width":
                                                                "56px",
                                                              display: "flex",
                                                              "justify-content":
                                                                "center",
                                                              "align-items":
                                                                "center",
                                                              position:
                                                                "relative",
                                                              top: "3px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              icon: "plus",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addOptions(
                                                                  item.options,
                                                                  item.optionsPushValue,
                                                                  item,
                                                                  i,
                                                                  one.dataList
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("添加")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            !item.isSeach
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "24px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (value, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            value.label +
                                                            Math.random(),
                                                          staticClass:
                                                            "tag-div",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "136px",
                                                                    "padding-right":
                                                                      "66px",
                                                                    "margin-bottom":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    span: 16,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "a-tag",
                                                                    {
                                                                      staticClass:
                                                                        "close-tag",
                                                                      attrs: {
                                                                        closable:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        dblclick:
                                                                          (
                                                                            e
                                                                          ) => {
                                                                            _vm.tagClick(
                                                                              e,
                                                                              value.label,
                                                                              index,
                                                                              item.options,
                                                                              i,
                                                                              one.dataList
                                                                            )
                                                                          },
                                                                        close:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.closeTag(
                                                                              index,
                                                                              item.options,
                                                                              i,
                                                                              one.dataList
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          value.label
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "24px",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (value, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key:
                                                            value.label +
                                                            Math.random(),
                                                          staticClass:
                                                            "tag-div",
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "0px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a-row",
                                                            [
                                                              _c(
                                                                "a-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "136px",
                                                                    "padding-right":
                                                                      "66px",
                                                                    "margin-bottom":
                                                                      "5px",
                                                                  },
                                                                  attrs: {
                                                                    span: 16,
                                                                  },
                                                                },
                                                                [
                                                                  _vm.isSeachItem(
                                                                    index
                                                                  )
                                                                    ? _c(
                                                                        "a-tag",
                                                                        {
                                                                          staticClass:
                                                                            "close-tag",
                                                                          attrs:
                                                                            {
                                                                              closable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            dblclick:
                                                                              (
                                                                                e
                                                                              ) => {
                                                                                _vm.tagClick(
                                                                                  e,
                                                                                  value.label,
                                                                                  index,
                                                                                  item.options,
                                                                                  i,
                                                                                  one.dataList
                                                                                )
                                                                              },
                                                                            close:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.closeTag(
                                                                                  index,
                                                                                  item.options,
                                                                                  i,
                                                                                  one.dataList
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              value.label
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                          ]
                                        : _vm._e(),
                                      item.type == "TAG"
                                        ? [
                                            _c(
                                              "a-form-item",
                                              { attrs: { label: "标签" } },
                                              [
                                                _c("tag-drag-select", {
                                                  staticStyle: {
                                                    "margin-top": "5px",
                                                  },
                                                  attrs: {
                                                    isPm: true,
                                                    "scope-id": i,
                                                    "prop-data-list":
                                                      item.options || [],
                                                  },
                                                  on: {
                                                    change: (tagList) =>
                                                      _vm.wordsFormChange(
                                                        tagList,
                                                        item.options,
                                                        index,
                                                        i
                                                      ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                })
                              : _vm._e(),
                            one.type != "INPUT_NUMBER"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "description",
                                    attrs: {
                                      label:
                                        one.type == "SELECT" ||
                                        one.type == "TAG"
                                          ? "选项个数"
                                          : "输入框字数",
                                    },
                                  },
                                  [
                                    one.type == "SELECT" || one.type == "TAG"
                                      ? _c("a-input-number", {
                                          attrs: { min: 0 },
                                          model: {
                                            value: one.dataList[0].selectCount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                one.dataList[0],
                                                "selectCount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "one.dataList[0].selectCount",
                                          },
                                        })
                                      : _c("edit-range-select", {
                                          attrs: {
                                            formData:
                                              one.dataList[0].limitRange,
                                            rangeKey: "type",
                                            valueKey: "value",
                                            needUnit: false,
                                          },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            one.type == "SELECT"
                              ? [
                                  _c(
                                    "a-form-item",
                                    { attrs: { label: "选项内容" } },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { type: "flex" } },
                                        [
                                          _c(
                                            "a-col",
                                            { staticStyle: { flex: "1" } },
                                            [
                                              _c("a-input", {
                                                attrs: {
                                                  placeholder: "请输入",
                                                },
                                                model: {
                                                  value:
                                                    one.dataList[0]
                                                      .optionsPushValue,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      one.dataList[0],
                                                      "optionsPushValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "one.dataList[0].optionsPushValue",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "max-width": "56px",
                                                    display: "flex",
                                                    "justify-content": "center",
                                                    "align-items": "center",
                                                    position: "relative",
                                                    top: "3px",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    icon: "plus",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addOptions(
                                                        one.dataList[0].options,
                                                        one.dataList[0]
                                                          .optionsPushValue,
                                                        one.dataList[0],
                                                        0,
                                                        one.dataList
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "添加\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !one.dataList[0].isSeach
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "24px",
                                          },
                                        },
                                        _vm._l(
                                          one.dataList[0].options,
                                          function (value, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  value.label + Math.random(),
                                                staticClass: "tag-div",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "136px",
                                                          "padding-right":
                                                            "66px",
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: { span: 16 },
                                                      },
                                                      [
                                                        _c(
                                                          "a-tag",
                                                          {
                                                            staticClass:
                                                              "close-tag",
                                                            attrs: {
                                                              closable: "",
                                                            },
                                                            on: {
                                                              dblclick: (e) => {
                                                                _vm.tagClick(
                                                                  e,
                                                                  value.label,
                                                                  index,
                                                                  one
                                                                    .dataList[0]
                                                                    .options,
                                                                  0,
                                                                  one.dataList
                                                                )
                                                              },
                                                              close: function (
                                                                $event
                                                              ) {
                                                                return _vm.closeTag(
                                                                  index,
                                                                  one
                                                                    .dataList[0]
                                                                    .options,
                                                                  0,
                                                                  one.dataList
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                value.label
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "24px",
                                          },
                                        },
                                        _vm._l(
                                          one.dataList[0].options,
                                          function (value, index) {
                                            return _c(
                                              "div",
                                              {
                                                key:
                                                  value.label + Math.random(),
                                                staticClass: "tag-div",
                                                staticStyle: {
                                                  "margin-bottom": "0px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-row",
                                                  [
                                                    _c(
                                                      "a-col",
                                                      {
                                                        staticStyle: {
                                                          "margin-left":
                                                            "136px",
                                                          "padding-right":
                                                            "66px",
                                                          "margin-bottom":
                                                            "5px",
                                                        },
                                                        attrs: { span: 16 },
                                                      },
                                                      [
                                                        _vm.isSeachItem(index)
                                                          ? _c(
                                                              "a-tag",
                                                              {
                                                                staticClass:
                                                                  "close-tag",
                                                                attrs: {
                                                                  closable: "",
                                                                },
                                                                on: {
                                                                  dblclick: (
                                                                    e
                                                                  ) => {
                                                                    _vm.tagClick(
                                                                      e,
                                                                      value.label,
                                                                      index,
                                                                      one
                                                                        .dataList[0]
                                                                        .options,
                                                                      0,
                                                                      one.dataList
                                                                    )
                                                                  },
                                                                  close: () =>
                                                                    _vm.closeTag(
                                                                      index,
                                                                      one
                                                                        .dataList[0]
                                                                        .options,
                                                                      0,
                                                                      one.dataList
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    value.label
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                ]
                              : _vm._e(),
                            one.type == "TAG"
                              ? _c(
                                  "a-form-item",
                                  { attrs: { label: "标签内容" } },
                                  [
                                    _c("tag-drag-select", {
                                      attrs: {
                                        isPm: true,
                                        "scope-id": 0,
                                        "prop-data-list":
                                          one.dataList[0].options || [],
                                      },
                                      on: {
                                        change: (tagList) =>
                                          _vm.wordsChange(
                                            tagList,
                                            one.dataList[0].options,
                                            index
                                          ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    }),
                  ]
                : _vm._e(),
              _c(
                "a-form-model-item",
                { staticClass: "add-item-form-item" },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择新增项",
                        value: "请选择新增项",
                      },
                      on: { change: _vm.formSelectChange },
                    },
                    _vm._l(
                      [
                        { value: "DYNAMIC_FORM", label: "动态表单" },
                        { value: "INPUT", label: "单行文本" },
                        { value: "TEXTAREA", label: "多行文本" },
                        { value: "TAG", label: "标签" },
                        { value: "SELECT", label: "下拉选择" },
                        { value: "INPUT_NUMBER", label: "数字输入框" },
                      ],
                      function (item) {
                        return _c(
                          "a-select-option",
                          { key: item.value, attrs: { value: item.value } },
                          [_vm._v(_vm._s(item.label))]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }