<template>
  <!-- 作品详情配置抽屉 -->
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    :permission="
      type == 'PRODUCT'
        ? domain == 'PRODUCT_DETAIL'
          ? !isTongChou
            ? 'isTongchou'
            : 'info:button:edit'
          : !isTongChou
          ? 'isTongchou'
          : 'coefficientPar:button:edit'
        : type == 'PLATFORM'
        ? domain == 'PRODUCT_DETAIL'
          ? !isTongChou
            ? 'isTongchou'
            : 'palatform:button:edit'
          : !isTongChou
          ? 'isTongchou'
          : 'palatform:coefficientPar:button:edit'
        : type == 'GLOBAL'
        ? domain == 'PRODUCT_DETAIL'
          ? 'system:productDetail:edit'
          : 'system:coefficientPar:edit'
        : ''
    "
    @show="show"
    destroy-on-close
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <div slot>
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" style="font-weight:400;" label="名称">{{ curRow.title }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sort }}</info-item>
      <info-item :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departments" :key="one.departId">{{ one.departName }}</a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用职位">
        <a-tag v-for="one in curRow.positions" :key="one.positionId"
          >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
        >
      </info-item>
      <info-item v-if="curRow.canChapter || domain != 'PRODUCT_DETAIL'" :label-width="labelWidth" label="适用阶段">
        <a-tag v-for="one in curRow.stages" :key="one.stageId"
          >{{ one.stageName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
        >
      </info-item>
      <info-item v-if="domain == 'PRODUCT_DETAIL'" label="适用到章节">
        {{ curRow.canChapter ? '是' : '否' }}
      </info-item>
      <info-item v-if="domain == 'TASK_DETAIL'" label="章节范围">
        <span>{{ curRow.chapterRange ? getCalcRange(curRow.chapterRange) : '' }}</span>
      </info-item>
      <info-item label="描述">
        {{ curRow.description }}
      </info-item>
      <info-item label="允许同步" v-if="isSync">
        {{ curRow.canSync || curRow.canSync === null ? '是' : '否' }}
      </info-item>
      <g-title>正文</g-title>
      <template v-if="curRow.cardList">
        <a-form v-for="one in curRow.cardList" :key="one.id">
          <a-form-item :label="one.type | typeFilter">
            {{ one.canAdd | canAddFilter }}
          </a-form-item>
          <a-form-item label="是否必填">
            {{ one.dataList ? (one.dataList[0].valueRequired == 'NO' ? '否' : '是') : '' }}
          </a-form-item>
          <a-form-item label="输入框说明">
            {{ one.dataList ? one.dataList[0].description : '' }}
          </a-form-item>
          <template v-if="one.type == 'DYNAMIC_FORM' && one.dataList">
            <div v-for="(item, itemIndex) in one.dataList" :key="itemIndex" style="margin-bottom:-12px;">
              <a-form-item label="子选项">
                <span>{{ item.value }}</span>
                <span :style="item.value ? 'margin-left:20px;' : ''">{{
                  item.type == 'INPUT' ? '文本录入' : item.type == 'SELECT' ? '下拉选项' : '标签'
                }}</span>
              </a-form-item>
              <!--             <a-form-item label="选项个数" v-if="one.type == 'DYNAMIC_FORM'">
              {{ item.selectCount }}
            </a-form-item> -->
              <div :style="item.type != 'INPUT' ? 'margin-bottom:36px;margin-top:24px;' : 'height: 11px;'">
                <div style="margin-bottom:0px;">
                  <a-row>
                    <a-col v-if="item.type == 'TAG'" :span="16" style="margin-left:134px;padding-right:66px;">
                      <template v-for="(i, index) in item.options">
                        <a-tag style="margin-bottom:8px;" :key="index">{{ i.label }}</a-tag>
                      </template>
                    </a-col>
                    <a-col v-else :span="16" style="margin-left:134px;padding-right:66px;margin-top:-8px">
                      <div style="margin-bottom:5px;" v-for="(i, index) in item.options" :key="index">
                        <span class="ant-badge-status-dot ant-badge-status-processing"> </span>
                        <span>{{ i.label }}</span>
                      </div>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
          </template>
          <template v-if="one.type != 'INPUT_NUMBER'">
            <a-form-item label="选项个数" v-if="one.type == 'SELECT' || one.type == 'TAG'">
              {{ one.dataList && one.dataList[0].selectCount }}
            </a-form-item>
            <a-form-item label="输入框字数" v-else>
              <div v-if="one.dataList[0].limitRange">
                {{ getLimitRange(one.dataList[0].limitRange) }}
              </div>
            </a-form-item>
          </template>
          <a-form-item
            :class="one.type == 'SELECT' ? 'option-item' : 'option-item option-tag-item'"
            v-if="one.type == 'SELECT' || one.type == 'TAG'"
            :label="one.type == 'SELECT' ? '选项内容' : '标签内容'"
            style="padding-right: 66px;"
          >
            <template v-if="one.dataList">
              <template
                style="margin-bottom:5px;width:100%;line-height:24px;padding-right:66px;"
                v-for="(i, idx) in one.dataList[0].options"
              >
                <a-tag :key="idx" style="margin-bottom:8px;" v-if="one.type == 'TAG'">{{ i.label }}</a-tag>
                <div v-else :key="idx" :style="idx == 0 ? '' : 'margin-top:-15px;'">
                  <span class="ant-badge-status-dot ant-badge-status-processing"> </span>
                  <span>{{ i.label }}</span>
                </div>
              </template>
            </template>
          </a-form-item>
        </a-form>
      </template>
    </div>
    <div slot="form">
      <g-title>基本信息</g-title>
      <a-form-model
        ref="dataForm"
        layout="horizontal"
        style="padding-bottom:65px"
        :model="formData"
        :rules="rules"
        v-bind="formLayout"
      >
        <a-form-model-item label="名称" class="first-form-item" prop="sequenceNo">
          <a-input v-model="formData.title" placeholder="请输入名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input v-model="formData.sort" placeholder="请输入序号"></a-input>
        </a-form-model-item>
        <a-form-model-item label="适用部门" prop="selectedDepart">
          <a-tree-select
            v-model="selectedDeparts"
            style="width: 100%"
            treeDefaultExpandAll
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <a-form-model-item label="适用职位" prop="selectedPositions">
          <a-tree-select
            v-model="selectedPositions"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionList"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changePositionList"
          />
        </a-form-model-item>
        <a-form-model-item label="适用阶段" v-if="formData.canChapter || domain == 'TASK_DETAIL'" prop="selectedStages">
          <a-tree-select
            v-model="selectedStages"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreeStageList"
            search-placeholder="选择阶段"
            placeholder="请选择适用阶段"
            @change="changeStageList"
          />
        </a-form-model-item>
        <a-form-item v-if="domain == 'PRODUCT_DETAIL'" label="适用到章节">
          <a-radio-group v-model="formData.canChapter">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="domain == 'TASK_DETAIL'" label="章节范围">
          <edit-range-select
            v-if="formData.chapterRange"
            :formData="formData.chapterRange"
            :rangeKey="'type'"
            :valueKey="'value'"
            :needUnit="false"
          />
        </a-form-item>
        <a-form-item label="描述">
          <a-input v-model="formData.description" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="允许同步" v-if="isSync">
          <a-switch v-model="canSync" />
        </a-form-item>
        <g-title>正文</g-title>

        <a-form-model-item v-for="(one, index) in formData.formItems" :key="index" :label="one.label" :prop="one.prop">
          <!-- TODO 动态 -->
        </a-form-model-item>
        <template v-if="formData.cardList">
          <template v-for="(one, index) in formData.cardList">
            <div class="edit-form" :key="one.id">
              <a-form-model-item :label="one.type | typeFilter" class="edit-form-remove">
                <a-checkbox v-model="one.canAdd" v-if="one.type == 'DYNAMIC_FORM'">
                  填写时是否允许加行
                </a-checkbox>
                <div v-else></div>
                <div class="card-remove">
                  <a-button
                    @click="deleteCard(index)"
                    style="position: relative"
                    shape="circle"
                    size="small"
                    icon="close"
                  />
                </div>
              </a-form-model-item>
              <a-form-item label="是否必填">
                <a-radio-group v-model="one.dataList[0].valueRequired">
                  <a-radio value="YES">是</a-radio>
                  <a-radio value="NO">否</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="输入框说明" class="description">
                <a-input v-model="one.dataList[0].description" placeholder="请输入" />
              </a-form-item>
              <template v-if="one.type == 'DYNAMIC_FORM'">
                <div v-for="(item, i) in one.dataList" :key="i">
                  <a-form-model-item class="dt-form" label="子选项">
                    <a-row>
                      <a-col style="width:100%;display:flex;flex:1;">
                        <a-input v-model="item.value" placeholder="请输入" />
                        <a-select
                          ref="select"
                          v-model="item.type"
                          style="margin-left:10px;"
                          @change="value => changeSelect(value, item)"
                          allowClear
                        >
                          <a-select-option value="INPUT">文本录入</a-select-option>
                          <a-select-option value="SELECT">下拉选项</a-select-option>
                          <a-select-option value="TAG">标签</a-select-option>
                        </a-select>
                      </a-col>
                      <a-col
                        style="min-width:45px;margin-left:10px;display: flex;justify-content: space-between;align-items: center;"
                      >
                        <a-button
                          class="item-btn"
                          shape="circle"
                          size="small"
                          icon="minus"
                          @click="delItem(one.dataList, i)"
                        />
                        <a-button
                          style="margin-left:8px;"
                          class="item-btn"
                          shape="circle"
                          size="small"
                          icon="plus"
                          @click="addItem(one.dataList, i)"
                        />
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <!--                 <a-form-item label="选项个数" v-if="item.options" class="description">
                  <a-input-number :min="0" v-model="item.selectCount" />
                </a-form-item> -->
                  <template v-if="item.type == 'SELECT'">
                    <a-form-item label="下拉选项">
                      <a-row>
                        <a-col style="flex:1;">
                          <a-input
                            v-model="item.optionsPushValue"
                            @change="
                              e => {
                                inputSearchChange(e, i, one.dataList)
                              }
                            "
                            placeholder="请输入"
                          ></a-input>
                        </a-col>
                        <a-col style="margin-left:10px;min-width:45px;">
                          <a-button
                            style="max-width: 56px;display: flex;justify-content: center;align-items: center;position: relative;top: 3px;"
                            type="text"
                            icon="plus"
                            @click="addOptions(item.options, item.optionsPushValue, item, i, one.dataList)"
                            >添加</a-button
                          >
                        </a-col>
                      </a-row>
                    </a-form-item>
                    <div style="margin-bottom:24px;" v-if="!item.isSeach">
                      <div
                        class="tag-div"
                        style="margin-bottom:0px;"
                        v-for="(value, index) in item.options"
                        :key="value.label + Math.random()"
                      >
                        <a-row>
                          <a-col :span="16" style="margin-left:136px;padding-right: 66px;margin-bottom:5px;">
                            <a-tag
                              class="close-tag"
                              closable
                              @dblclick="
                                e => {
                                  tagClick(e, value.label, index, item.options, i, one.dataList)
                                }
                              "
                              @close="closeTag(index, item.options, i, one.dataList)"
                              >{{ value.label }}</a-tag
                            >
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                    <div style="margin-bottom:24px;" v-else>
                      <div
                        class="tag-div"
                        style="margin-bottom:0px;"
                        v-for="(value, index) in item.options"
                        :key="value.label + Math.random()"
                      >
                        <a-row>
                          <a-col :span="16" style="margin-left:136px;padding-right: 66px;margin-bottom:5px;">
                            <a-tag
                              class="close-tag"
                              closable
                              v-if="isSeachItem(index)"
                              @dblclick="
                                e => {
                                  tagClick(e, value.label, index, item.options, i, one.dataList)
                                }
                              "
                              @close="closeTag(index, item.options, i, one.dataList)"
                              >{{ value.label }}</a-tag
                            >
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type == 'TAG'">
                    <a-form-item label="标签">
                      <tag-drag-select
                        style="margin-top:5px;"
                        :isPm="true"
                        :scope-id="i"
                        @change="tagList => wordsFormChange(tagList, item.options, index, i)"
                        :prop-data-list="item.options || []"
                      ></tag-drag-select>
                    </a-form-item>
                  </template>
                </div>
              </template>
              <a-form-item
                v-if="one.type != 'INPUT_NUMBER'"
                :label="one.type == 'SELECT' || one.type == 'TAG' ? '选项个数' : '输入框字数'"
                class="description"
              >
                <a-input-number
                  v-if="one.type == 'SELECT' || one.type == 'TAG'"
                  :min="0"
                  v-model="one.dataList[0].selectCount"
                />
                <edit-range-select
                  v-else
                  :formData="one.dataList[0].limitRange"
                  :rangeKey="'type'"
                  :valueKey="'value'"
                  :needUnit="false"
                />
              </a-form-item>
              <template v-if="one.type == 'SELECT'">
                <a-form-item label="选项内容">
                  <a-row type="flex">
                    <a-col style="flex:1">
                      <a-input v-model="one.dataList[0].optionsPushValue" placeholder="请输入"> </a-input>
                    </a-col>
                    <a-col>
                      <a-button
                        style="max-width: 56px;display: flex;justify-content: center;align-items: center;position: relative;top: 3px;"
                        type="text"
                        icon="plus"
                        @click="
                          addOptions(
                            one.dataList[0].options,
                            one.dataList[0].optionsPushValue,
                            one.dataList[0],
                            0,
                            one.dataList
                          )
                        "
                        >添加
                      </a-button>
                    </a-col>
                  </a-row>
                </a-form-item>
                <div style="margin-bottom:24px;" v-if="!one.dataList[0].isSeach">
                  <div
                    class="tag-div"
                    style="margin-bottom:0px;"
                    v-for="(value, index) in one.dataList[0].options"
                    :key="value.label + Math.random()"
                  >
                    <a-row>
                      <a-col :span="16" style="margin-left:136px;padding-right: 66px;margin-bottom:5px;">
                        <a-tag
                          class="close-tag"
                          closable
                          @dblclick="
                            e => {
                              tagClick(e, value.label, index, one.dataList[0].options, 0, one.dataList)
                            }
                          "
                          @close="closeTag(index, one.dataList[0].options, 0, one.dataList)"
                          >{{ value.label }}</a-tag
                        >
                      </a-col>
                    </a-row>
                  </div>
                </div>
                <div style="margin-bottom:24px;" v-else>
                  <div
                    class="tag-div"
                    style="margin-bottom:0px;"
                    v-for="(value, index) in one.dataList[0].options"
                    :key="value.label + Math.random()"
                  >
                    <a-row>
                      <a-col :span="16" style="margin-left:136px;padding-right: 66px;margin-bottom:5px;">
                        <a-tag
                          class="close-tag"
                          closable
                          v-if="isSeachItem(index)"
                          @dblclick="
                            e => {
                              tagClick(e, value.label, index, one.dataList[0].options, 0, one.dataList)
                            }
                          "
                          @close="() => closeTag(index, one.dataList[0].options, 0, one.dataList)"
                          >{{ value.label }}</a-tag
                        >
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </template>
              <a-form-item v-if="one.type == 'TAG'" label="标签内容">
                <tag-drag-select
                  :isPm="true"
                  :scope-id="0"
                  @change="tagList => wordsChange(tagList, one.dataList[0].options, index)"
                  :prop-data-list="one.dataList[0].options || []"
                ></tag-drag-select>
              </a-form-item>
            </div>
          </template>
        </template>
        <a-form-model-item class="add-item-form-item">
          <a-select placeholder="请选择新增项" value="请选择新增项" @change="formSelectChange">
            <a-select-option
              v-for="item in [
                { value: 'DYNAMIC_FORM', label: '动态表单' },
                { value: 'INPUT', label: '单行文本' },
                { value: 'TEXTAREA', label: '多行文本' },
                { value: 'TAG', label: '标签' },
                { value: 'SELECT', label: '下拉选择' },
                { value: 'INPUT_NUMBER', label: '数字输入框' }
              ]"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <!-- <dynamic-card-item :prop-options="propOptions" mode="multiple" /> -->
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import EditRangeSelect from '@/components/edit/edit-range-select.vue'
import DynamicCardItem from '@/components/dynamic-card-item'
import DynamicFormItem from '@/components/dynamic-form-item'
import TagDragSelect from '@/components/TagDragSelect'
const defaultSelectedDeparts = []
const defaultSelectedPositions = []
const defaultFormData = {
  textLayout: 1,
  styleItem: {
    textAlign: 'left',
    lineHeight: '1.2',
    scaleY: 1,
    scaleX: 1,
    fontSize: 8,
    fontColor: '#000000',
    antiAliasMethod: 'antiAliasSharp'
    // outlineColor: '#ffffff',
    // outlineWidth: 4,
  },
  typeId: 1,
  classId: 1,
  isSync: false
}
export default {
  mixins: [DrawerMixins],
  components: { DynamicCardItem, DynamicFormItem, TagDragSelect, EditRangeSelect },
  data() {
    return {
      url: {
        list: '',
        edit: '/productDetail',
        add: '/productDetail'
      },
      canSync: true,
      oldFormData: {},
      selectedPositions: [],
      selectedStages: [],
      tooltipValue: '',
      isSeach: false,
      rules: {},
      labelWidth: '120',
      formLayout: {
        labelCol: {
          style: {
            width: '120px',
            display: 'flex'
          },
          span: 8
        },
        wrapperCol: {
          span: 16
        }
      },
      optionsPushValue: '',
      gridLayout: {
        type: 'flex',
        justify: 'end'
      }
    }
  },
  props: {
    domain: null,
    isSync: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      default: () => 'GLOBAL'
    },
    relationId: {
      type: String,
      default: () => ''
    },
    isTongChou: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    typeFilter(type) {
      const typeMap = {
        DYNAMIC_FORM: '动态表单',
        INPUT: '单行文本',
        TEXTAREA: '多行文本',
        RADIO: '单项选择',
        SELECT: '下拉选择',
        TAG: '标签',
        INPUT_NUMBER: '数字输入框'
      }
      return typeMap[type]
    },
    canAddFilter(canAdd) {
      const canAddMap = {
        true: '允许加行',
        false: '不允许加行',
        null: ''
      }
      return canAddMap[canAdd]
    }
  },
  created() {
    this.getDepartList()
    this.getPosition()
    this.getStageList()
  },
  computed: {
    getTreePositionList() {
      console.log({ 'this.positionList': this.positionList })
      let treePositionList = this.positionList
        .filter(i => i)
        .map(({ id: key, id: value, name: title }) => ({ title, key, value }))
      console.log({ treePositionList })
      let arr1 = []
      treePositionList.forEach(item => {
        arr1.push({ title: item.title + '-查看', key: item.key + '-VIEW', value: item.value + '-VIEW' })
        arr1.push({ title: item.title + '-编辑', key: item.key + '-EDIT', value: item.value + '-EDIT' })
      })
      treePositionList = arr1
      return treePositionList
    },
    getTreeStageList() {
      console.log({ 'this.stageList': this.stageList })
      let treeStageList = this.stageList.map(item => {
        return {
          title: item.stageName,
          key: item.stageId,
          value: item.stageId
        }
      })
      console.log({ treeStageList })
      let arr1 = []
      treeStageList.forEach(item => {
        arr1.push({
          title: item.title + '-查看',
          key: item.key + '-VIEW' + '-' + item.title,
          value: item.value + '-VIEW' + '-' + item.title
        })
        arr1.push({
          title: item.title + '-编辑',
          key: item.key + '-EDIT' + '-' + item.title,
          value: item.value + '-EDIT' + '-' + item.title
        })
      })
      treeStageList = arr1
      return treeStageList
    }
  },
  watch: {
    formData: {
      handler(newValue, oldValue) {
        console.log(newValue, 456)
        this.canSync = newValue.canSync === null ? true : newValue.canSync
        if (oldValue.title) this.canSync = false
      },
      deep: true
    }
  },
  methods: {
    stageChange(values) {
      console.log(values)
    },
    getCalcRange(data) {
      let text = ''
      let unit = '话'
      if (data.type) {
        switch (data.type) {
          case 'GT':
            data.value != null && (text = '>' + data.value + unit)
            break
          case 'LT':
            data.value != null && (text = '<' + data.value + unit)
            break
          case 'EQ':
            data.value != null && (text = '=' + data.value + unit)
            break
          case 'BETWEEN':
            data.start != null && (text = data.start + '~' + data.end + unit)
            break
        }
        return text
      }
      return text
    },
    changePositionList(keys, t, { triggerNode }) {
      console.log('changePositionList', { keys, t, triggerNode })
      this.formData.positions = keys.map(item => {
        return {
          positionId: item.split('-')[0],
          type: item.split('-')[1]
        }
      })
    },
    changeStageList(keys, t, { triggerNode }) {
      console.log('changeStageList', { keys, t, triggerNode })
      this.formData.stages = keys.map(item => {
        return {
          stageId: item.split('-')[0],
          type: item.split('-')[1],
          stageName: item.split('-')[2]
        }
      })
    },
    getLimitRange(limitRange) {
      let obj = { ...limitRange }
      switch (obj.type) {
        case 'GT':
          return obj.value ? '>' + obj.value : ''
        case 'LT':
          return obj.value ? '<' + obj.value : ''
        case 'BETWEEN':
          return obj.start ? obj.start + '~' + obj.end : ''
      }
    },
    wordsFormChange(tagList, options, index, i) {
      let arr = []
      tagList.forEach(tag => {
        let item = { value: tag.value, label: tag.value }
        arr.push(item)
      })
      this.formData.cardList[index].dataList[i].options = [...arr]
    },
    wordsChange(tagList, options, index) {
      let arr = []
      tagList.forEach(tag => {
        let item = { value: tag.value, label: tag.value }
        arr.push(item)
      })
      this.formData.cardList[index].dataList[0].options = [...arr]
      console.log(this.formData, 'options')
    },
    inputSearchChange(e, index, dataList) {
      if (!e.target.value) dataList[index].isSeach = false
    },
    formSelectChange(value) {
      const item = {
        type: value,
        canAdd: null,
        dataList: [
          {
            valueRequired: '',
            options: null,
            isSeach: false,
            type: 'INPUT',
            valueRequired: 'NO',
            limitRange: {
              type: 'GT',
              unit: null,
              value: '',
              start: null,
              end: null,
              id: null
            }
          }
        ]
      }
      let cardList = this.formData.cardList || []
      cardList.push(item)
      this.formData = Object.assign({}, this.formData, { cardList })
    },
    tagClick(e, label, index, options, i, dataList) {
      /* this.tooltipValue=value */
      const _this = this
      e.target.style.borderColor = '#5389DF'
      e.target.innerHTML = `<input placeholder='请输入' style='box-sizing: border-box;border:none;background-color: #fafafa;width:100%;height:20px;padding-left:0' /><i aria-label='图标: close' tabindex='-1' class='anticon anticon-close'><svg viewBox='64 64 896 896' data-icon='close' width='1em' height='1em' fill='currentColor' aria-hidden='true' focusable='false' class=''><path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path></svg></i>`
      e.target.childNodes[0].value = label
      e.target.childNodes[0].onmouseover = function() {
        this.style.outline = 'none'
      }
      e.target.childNodes[1].onclick = function() {
        return _this.closeTag(index, options, i, dataList)
      }
      e.target.childNodes[0].onkeyup = function(e) {
        if (e.keyCode == 13) {
          let text = e.target.value.trim()
          e.target.value = text
          options[index].label = e.target.value
          options[index].value = e.target.value
          _this.isSeach = false
        }
      }
      e.target.childNodes[0].onblur = function(e) {
        let text = e.target.value.trim()
        e.target.value = text
        options[index].label = e.target.value
        options[index].value = e.target.value
        if (!options[index].value) options.splice(index, 1)
        _this.isSeach = false
      }
      e.target.childNodes[0].focus()
    },
    isSeachItem(index) {
      return this.searchIndex.some((e, i) => {
        return e == index
      })
    },
    fuzzyQuery(options, optionsPushValue, index, dataList) {
      this.searchIndex = []
      for (let i in options) {
        if (options[i].label.indexOf(optionsPushValue) >= 0) this.searchIndex.push(i)
      }
      dataList[index].isSeach = true
    },
    addOptions(options, optionsPushValue, i, index, dataList) {
      if (optionsPushValue) {
        if (!options) {
          i.options = []
          options = i.options
        }
        let item = Object.assign({}, { value: optionsPushValue, label: optionsPushValue })
        if (
          !options.some(option => {
            return option.value == item.value
          })
        )
          options.push(item)
        i.optionsPushValue = ''
        dataList[index].isSeach = false
      }
    },
    closeTag(index, options, i, dataList) {
      this.$nextTick(() => {
        options.splice(index, 1)
        console.log(dataList[i])
        if (dataList[i].isSeach) dataList[i].isSeach = false
      })
    },
    changeSelect(value, item) {
      if (value === 'SELECT' || value === 'TAG') {
        item.options = []
      } else {
        delete item.options
      }
    },
    deleteCard(index) {
      this.formData.cardList.splice(index, 1)
    },
    delItem(dataList, index) {
      dataList.splice(index, 1)
    },
    addItem(dataList, index) {
      dataList.splice(index + 1, 0, { value: '', type: '', options: null })
    },
    initForm() {
      this.formData = {
        ...defaultFormData
      }
      this.selectedDeparts = [...defaultSelectedDeparts]
      this.selectedPositions = [...defaultSelectedPositions]
      this.selectedStages = []
    },
    setFormData() {
      this.formData.type = this.type
      this.formData.relationId = this.relationId
      this.formData.canSync = this.canSync
      this.domain == 'TASK_DETAIL' && (this.formData.canChapter = true)
      this.domain && (this.formData.domain = this.domain)
      this.$nextTick(() => {
        this.canSync = this.formData.canSync
      })
    },
    show() {
      console.log('显示...', { 'this.curRow': this.curRow })
      this.confirmBtnDisable = true
      if (this.curRow.canSync || this.curRow.canSync === null) {
        this.canSync = true
      } else {
        this.canSync = false
      }
      this.getPosition()
      this.getDepartList()
      if (this.isEdit) {
        this.formData = this.curRow
        // console.log(this.formData, this.curRow,"this.formData = this.curRow")
      }
      if (this.domain == 'TASK_DETAIL') {
        if (!this.formData.chapterRange&&this.setTitle=='新建') {
          this.formData.chapterRange = { type: 'EQ', unit: null, value: '', start: null, end: null, id: null }
        }
      }
      this.oldFormData = { ...this.formData }
    },
    startEdit() {
      if (this.domain == 'TASK_DETAIL') {
        if (!this.formData.chapterRange) {
          this.formData.chapterRange = { type: 'EQ', unit: null, value: '', start: null, end: null, id: null }
        }
      }
      const departments = this.formData.departments
      const positions = this.formData.positions
      const stages = this.formData.stages
      /* this.formData = Object.assign({}, this.formData, { canChapter:false }); */
      this.selectedDeparts = departments?.map(item => item.departId)
      this.selectedPositions = positions?.map(item => item.positionId + '-' + item.type)
      this.selectedStages = stages?.map(item => item.stageId + '-' + item.type + '-' + item.stageName)
      let arr = []
      this.selectedPositions = [...arr, ...this.selectedPositions]
      this.formData.cardList.forEach(one => {
        one.dataList[0].limitRange ||
          (one.dataList[0].limitRange = {
            type: 'GT',
            unit: null,
            value: '',
            start: null,
            end: null,
            id: null
          })
      })
      this.oldFormData = { ...this.formData }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .option-tag-item {
  .ant-form-item-children {
    display: flex;
    flex-wrap: wrap;
    margin-top: 13px;
  }
}
/deep/ .ant-badge-status-processing {
  background-color: rgba(0, 0, 0, 0.65);
  margin-right: 3px;
  &::after {
    border: none;
  }
}
/deep/ .button-group {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  margin-bottom: 10px;
  .ant-btn {
    margin-right: 0;
  }
}
/deep/ .tag-drag-select {
  .ul {
    padding: 10px 20px;
    .li {
      line-height: 22px;
      margin: 10px 0 0 0;
    }
  }
}
/deep/ .description {
  .ant-form-item-control-wrapper {
    padding-right: 66px;
  }
  .ant-form-item-control {
    margin-top: 2px;
  }
}
/deep/ .dt-form {
  .ant-form-item-control {
    margin-top: 4px;
  }
}
/deep/ .option-item {
  .ant-form-item-control-wrapper {
    width: 66%;
    .ant-form-item-control {
      width: 100%;
      .ant-form-item-children {
        width: 100%;
      }
    }
  }
}
/deep/ .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: rgba(0, 0, 0, 0.65);
  overflow: unset;
}
/deep/ .stage-select .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: #bfbfbf;
}

/deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 4px;
}

/deep/ .first-form-item {
  label {
    font-weight: 400;
  }
}

/deep/ .tag-div .ant-tag {
  width: 100%;
  white-space: pre-wrap;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/ .edit-form-remove .ant-form-item-children {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
/deep/ .edit-form-remove .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  .ant-form-item-control {
    width: 100%;
  }
}
.card-remove {
  display: flex;
  align-items: center;
  width: 58px;
  justify-content: end;
}

.add-item-form-item {
  width: 120px;

  /deep/ .ant-col {
    width: inherit;
  }
}

.ant-form > .ant-form-item:first-child {
  /deep/ .ant-form-item-label {
    font-weight: 900;
  }
}

.edit-form > .ant-form-item:first-child {
  /deep/ .ant-form-item-label {
    font-weight: 900;
  }
}
/deep/ .ant-form-item {
  max-width: 100%;
}

/deep/ .ant-form-item-label {
  width: 120px;
  margin-right: 16px;
}

/deep/ .ant-row {
  display: flex;
}

.dynamic-card-prop-item {
  width: 100%;
}

/deep/ .ant-form-item-children {
  .ant-row-flex {
    & > .ant-col {
      & > * {
        margin-left: 10px;
      }

      &:first-child > * {
        margin-left: 0px;
      }
    }
  }
}
</style>
